/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Title, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Naše reference – infratopení Proficomfort.cz"} description={"Prohlédněte si námi navržené a realizované infratopení."}>
        <ColumnCover name={"g5a9uwv0uq"}>
          
          <Column className="--menu pb--12 pt--12" anim={null} menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
            
            <Menu className="--full" fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box pl--06">
                
                <Image className="--left" alt={"Logo PROFICOMFORT – infratopení"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} url={"https://proficomfort.cz"} use={"url"} href={"https://proficomfort.cz"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":240}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} target={null}>
                </Image>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/"} target={""} content={"Úvod"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} target={""} content={"O NÁS"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} target={""} content={"Infratopení"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={""} content={"PRODUKTY"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} target={""} content={"KE STAŽENÍ"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} target={""} content={"KONTAKT"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-m6ah0p js-anim  --anim3 pt--80" anim={"3"} name={"szq16opjo9n"} style={{"marginTop":128,"marginBottom":103,"paddingBottom":375}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37846/d084f871431a4a6a9aef0f2e265f3828_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <TitleMain className="title-box w--900 title-box--invert" content={"Reference"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--0 pl--12" name={"81gyokgtwg"} style={{"backgroundColor":"rgba(255,255,255,1)"}} lightbox={true}>
        </Column>


        <Column className="pb--60 pt--60" name={"7dcisytk37o"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/1567cc67058a4a87a6d7e18cb088265b_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/1567cc67058a4a87a6d7e18cb088265b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/1567cc67058a4a87a6d7e18cb088265b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/1567cc67058a4a87a6d7e18cb088265b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/6c72c9c24e9b40cead53657372537382_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/6c72c9c24e9b40cead53657372537382_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/e42b1ff48e9a4d22ab1b7a11eeb42fcc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/e42b1ff48e9a4d22ab1b7a11eeb42fcc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/e42b1ff48e9a4d22ab1b7a11eeb42fcc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/e42b1ff48e9a4d22ab1b7a11eeb42fcc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/e02b85b122234a90aac9926a88faf9ca_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/e02b85b122234a90aac9926a88faf9ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/e02b85b122234a90aac9926a88faf9ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/e02b85b122234a90aac9926a88faf9ca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37846/e02b85b122234a90aac9926a88faf9ca_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/08a49abfd219400cbb78d8b3dab4fc3c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/08a49abfd219400cbb78d8b3dab4fc3c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/08a49abfd219400cbb78d8b3dab4fc3c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/08a49abfd219400cbb78d8b3dab4fc3c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37846/08a49abfd219400cbb78d8b3dab4fc3c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/ebd328486827429ea64244ca27886a67_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/ebd328486827429ea64244ca27886a67_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/ebd328486827429ea64244ca27886a67_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/ebd328486827429ea64244ca27886a67_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/6da2fb3c306e4b7bb2dee8ebac2e6e1b_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/6da2fb3c306e4b7bb2dee8ebac2e6e1b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/6da2fb3c306e4b7bb2dee8ebac2e6e1b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/6da2fb3c306e4b7bb2dee8ebac2e6e1b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37846/6da2fb3c306e4b7bb2dee8ebac2e6e1b_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/fe62f884bcf94858b6e38db6911d665d_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37846/fe62f884bcf94858b6e38db6911d665d_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"rbwq5sr0yyn"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/353c2404ec434caa9bf0dba9e976e5cc_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/353c2404ec434caa9bf0dba9e976e5cc_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/353c2404ec434caa9bf0dba9e976e5cc_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/353c2404ec434caa9bf0dba9e976e5cc_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/353c2404ec434caa9bf0dba9e976e5cc_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/37846/cd21178e5e6a4783ac410fcd72120d8a_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/0e3f26e70e284f10912eeb03542fea2f_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/0e3f26e70e284f10912eeb03542fea2f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/0e3f26e70e284f10912eeb03542fea2f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/0e3f26e70e284f10912eeb03542fea2f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/0e3f26e70e284f10912eeb03542fea2f_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/70a7783497db4cf19556c5ff4042b088_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/70a7783497db4cf19556c5ff4042b088_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/70a7783497db4cf19556c5ff4042b088_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/70a7783497db4cf19556c5ff4042b088_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/70a7783497db4cf19556c5ff4042b088_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/ee39bf90f23b4d39a4b16abca6ed36aa_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/ee39bf90f23b4d39a4b16abca6ed36aa_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/ee39bf90f23b4d39a4b16abca6ed36aa_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/ee39bf90f23b4d39a4b16abca6ed36aa_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/ee39bf90f23b4d39a4b16abca6ed36aa_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/e8a6b7f683374909b6e728c3e2f9cdcd_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/e8a6b7f683374909b6e728c3e2f9cdcd_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37846/e8a6b7f683374909b6e728c3e2f9cdcd_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37846/e8a6b7f683374909b6e728c3e2f9cdcd_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37846/e8a6b7f683374909b6e728c3e2f9cdcd_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/418190ccf58448318fdb4121a45bfeb8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/418190ccf58448318fdb4121a45bfeb8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/418190ccf58448318fdb4121a45bfeb8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/418190ccf58448318fdb4121a45bfeb8_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37846/b8ef27330e924cfea286a898ba35a042_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37846/b8ef27330e924cfea286a898ba35a042_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37846/b8ef27330e924cfea286a898ba35a042_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37846/b8ef27330e924cfea286a898ba35a042_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-10i2o0w bg--center --parallax pb--80 pt--80" name={"31m9acmz1vl"} style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mb--10 mt--10" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --center pb--10" anim={"5"} animS={"5"} style={{"maxWidth":1440}}>
              
              <Title className="title-box title-box--invert" content={"Vyberte si z naši široké nabídky"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 w--900 swpf--uppercase ls--0" href={"/"} content={"<span style=\"color: rgb(255, 255, 255);\">Prohlížet produkty</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Služby firmy Proficomfort v oblasti infratopení\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Cenové návrhy ZDARMA</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Poradenství&nbsp; infra topení přímo u Vás</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Bohaté zkušenosti s realizací (19 let)</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Vysoká kvalita a dlouhá životnost infrapanelů</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Doručení ZDARMA</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Zajištění montáže&nbsp; i elektroinstalace</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Zajištění revize infračerveného topného systému</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Záruční a pozáruční servis včetně náhradních dílů</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Proč si vybrat k realizaci infratopení právě Proficomfort ?\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Vzhledem k více než 19 leté historii firmy Proficomfort jsme schopni nalézt řešení vytápění infračervenými topnými panely pro jakýkoliv obytný či komerční objekt. Máme dnes mnoho spokojených zákazníků z celé ČR ale i v zahraničí. Naše infrapanely zlepšují tepelný komfort v mnoha rodinných domech, kancelářích, prodejnách, výrobních prostorách včetně velkých objektů, jako jsou bytové a obchodní domy.\n\nPřesvědčte se o naší odbornosti a kvalitě v oblasti infračerveného topení. Podívejte se na naše reference a rozšiřte řady našich spokojených zákazníků.\n\nInfratopení je zdravé, čisté, komfortní, hygienické, tiché a příjemné. Sálavé infrapanely mají snadnou regulaci díky termostatu. Velkou výhodou infrapanelů je dosažitelnost zdroje elektrické energie. Provozní náklady vytápění infra topnými panely jsou jedny z nejnižších.&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1knc10f bg--center --parallax pb--60 pt--60" style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3 mb--40 mt--25" style={{"maxWidth":1440}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Logo – infratopení – PROFICOMFORT"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} content={null}>
              </Image>

              <Text className="text-box fs--18 w--600 text-box--invert mt--25" content={"<span style=\"color: rgb(127, 129, 150);\">Hosov 65<br>586 01 Jihlava</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--10" content={"<span style=\"color: rgb(127, 129, 150);\">+420 776 117 775<br>nekula@proficomfort.cz</span><br>"}>
              </Text>

              <Image className="pl--06" alt={""} src={"https://cdn.swbpg.com/o/11212/44bad18f84e0428187e9d7bd02eaea99.svg"} svg={false} href={"https://www.facebook.com/proficomfort/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":27}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Produkty</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} content={"<span style=\"color: rgb(127, 129, 150);\">Infrapanely do domácnosti</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/elektricke-podlahove-topeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Elektrické podlahové topení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/termostaty-a-regulace"} content={"<span style=\"color: rgb(127, 129, 150);\">Termostaty a regulace</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/prumyslove-salave-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Průmyslové sálavé zářiče</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/halogenove-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Halogenové zářiče</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Navigace</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/"} content={"<span style=\"color: rgb(127, 129, 150);\">Úvod</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={null} content={"<span style=\"color: rgb(127, 129, 150);\">Produkty</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} content={"<span style=\"color: rgb(127, 129, 150);\">O nás</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Ke stažení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} content={"<span style=\"color: rgb(127, 129, 150);\">Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" anim={null} animS={null} style={{"backgroundColor":"rgba(216,36,36,1)"}} border={null}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\" style=\"color: rgb(255, 255, 255);\">Tvorba webových stránek</a>: saywebpage.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right fs--18 w--600 text-box--invert" content={"2023<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}